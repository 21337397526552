html {
  scroll-behavior: smooth;
}

#page-menuonline {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ::-webkit-scrollbar {
    display: none;
  }
}

.menuonline-header-info {
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
}

.menuonline-title-info {
  margin-left: 12px;
  margin-top: 0px;
}

.menuonline-name {
  font-weight: 800;
  font-size: 28px;
  font-family: Ubuntu;
  color: black;
}

.menuonline-subtitle {
  font-size: 18px;
  font-family: Ubuntu;
}

.ul-menuonline .li-menuonline {
  text-transform: uppercase;
  font-family: Ubuntu;
  font-weight: 700;
  font-size: 15px;
  color: #0c0901;
  width: 100%;
  margin: 0;
  padding: 0;
}

.ul-menuonline {
  margin-left: 12px;
  margin-right: 12px;
}

.menuonline-menu-item {
  padding-left: 12px;
  padding-right: 12px;
}

/* MENU OPTIONS  */
.menu-option_grid-content {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: 10px;
}

/* MENU OPTIONS  */
hr.total-price-separator-solid {
  border-top: 1px solid #eeee;
  margin-top: 20px;
  margin-bottom: 12px;
}

.tile-price_itemName {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tile-price-section-title .tile-price_itemName {
  display: contents;
  text-transform: uppercase;
}

.tile-price-section-title .tile-price_itemName::after {
  content: "";
  display: inline-block;
  flex: 1;
  border-bottom: 1px dotted currentColor;
  transform: translateY(-0.65ex);
  margin: 0 0.25ex;
}

@media screen and (max-width: 500px) {
  .menuonline-menu-item {
    margin-top: 10px;
  }

  .menuonline-name {
    font-size: 20px;
  }

  .menuonline-subtitle {
    font-size: 14px;
  }

  .menuonline-image-specs {
    height: 20vh;
  }

  .tile-price_itemName {
    font-size: 13px;
    text-transform: lowercase;
  }
}
