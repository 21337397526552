:root {
  --primary-color: #34cb79;
  --title-color: #678f8e;
  --text-color: #6c6c80;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0px solid #eeee;
}

body {
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
  color: var(--text-color);
  font-family: Ubuntu;
}

body,
input,
button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Ubuntu;
}

h3 {
  text-align: center;
}

.cursor {
  cursor: 'pointer';
}

.section-title .itemName {
  display: contents;
  text-transform: uppercase;
}

.section-title .itemName::after {
  content: '';
  display: inline-block;
  flex: 1;
  border-bottom: 1px dotted currentColor;
  transform: translateY(-0.65ex);
  margin: 0 0.25ex;
}

/* Items to Tile Picture */
.grid-item-picture-specs {
  grid-area: image;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin-left: 15px;
  object-fit: cover;
}

.ul-tile-info {
  margin-left: 12px;
  margin-right: 12px;
}

.li-tile-info {
  font-family: Ubuntu;
  font-weight: 700;
  font-size: 15px;
  color: #0c0901;
  width: 100%;
  margin: 0;
  padding: 0;
}

.itemPrice {
  font-size: 18px;
  text-align: end;
}

.alert-danger {
  color: red;
}

.filters-menu {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  -ms-overflow-style: none;
  scrollbar-width: 2px;
}

.filter-menu-item {
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  padding: 10px;
  margin-left: 4px;
  margin-right: 4px;
  border: 2px solid #eeee;
  border-radius: 20px;
}

.filter-menu-anchor {
  text-decoration: none;
  font-size: 16px;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}

.fade-exit-done {
  opacity: 0;
}

/* Overriding default values of React Modal Confirmation */
.react-confirm-alert-body > h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 8px;
}

.react-confirm-alert-button-group > button {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 18px;
}

.react-confirm-alert-button-group > button:first-of-type {
  background: #2fb86e;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Custom Antd */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;

  max-height: 15%;
  overflow-y: hidden;
}

.row-dragging td {
  padding: 30px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.sidebar-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.admin-sidebar-logo {
  height: 60px;
  margin: 16px;
}

.admin-sidebar-logo_collapsed {
  height: 100px;
  margin: 0px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
/* Custom Antd */

.label-input-field {
  color: #17181f;
  font-weight: bold;
  font-size: 13px;
}

.fixed-modal-height {
  height: 60vh;
  max-height: 60vh;
} 

/* Adopt override - banner cookies */
#adopt-controller-button {
  z-index: 2 !important;
}
/* Adopt override - banner cookies */

.react-confirm-alert-overlay {
  z-index: 999999 !important;
}

/* Media Queries */
@media screen and (max-width: 500px) {
  .grid-item-picture-specs {
    width: 86px;
    height: 86px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 375px) {
  .itemPrice {
    font-size: 16px !important;
  }
  .filters-menu {
    width: 350px;
  }
}

@media screen and (max-width: 320px) {
  .itemPrice {
    font-size: 14px !important;
  }

  .grid-item-specs {
    font-size: 14px;
  }

  .grid-item-picture-specs {
    width: 78px;
    height: 78px;
    margin-left: 15px;
  }
}

@primary-color: #31B86F;@btn-border-radius-base: 10px;@btn-height-base: 45px;@btn-font-family: Ubuntu, sans-serif;@btn-font-weight: bold;