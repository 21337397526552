.rodeadouro-menu-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#page-rodeadouro {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rodeadouro-icon {
  background: #618138;
  color: #ffffff;
  margin: 10px 10px 10px 10px;

  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 22px;
  line-height: 42px;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.feedback-item {
  display: block;
  margin: 0 auto;
  width: fit-content;
  padding-bottom: 16px;
}
