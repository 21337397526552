#page-internal-page {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-specs {
  width: 60%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: block;
  margin: auto;
}

#page-internal-page .main-internal-page {
  box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
  text-align: left;
  background-color: #fff;
  max-width: 600px;
}

#page-internal-page .internal-page-name {
  font-weight: 800;
  font-size: 28px;
  text-align: center;
  font-family: Ubuntu;
}

.ul-internal-page .li-internal-page {
  font-family: Ubuntu;
  font-weight: 700;
  font-size: 15px;
  color: #678f8e;
  width: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 16px;
}

.ul-internal-page {
  margin-left: 12px;
  margin-right: 12px;
}

.demo-a {
  color: #678f8e;
}