/* Reset the default css */
.PhoneInputInput:focus {
  outline: none;
}

.phone-number-mask-input {
  padding: 18.5px 14px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  height: 40px;
}

.phone-number-mask-input:focus-within {
  border-color: #31B86F;
}
