.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal {
  z-index: 1072;
}
.modal .tooltip,
.modal .popover {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  /* transform: translate(0, -25%);*/
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  /* transform: translate(0, -25%); */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.16;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: '';
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4375rem;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem;
  border-bottom: 0 solid #e9ecef;
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}
.modal-header .close {
  padding: 1.25rem;
  margin: -1.25rem -1.25rem -1.25rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.1;
}
h6.modal-title {
  font-size: 1rem;
  font-weight: 600;
  margin-block-start: 0px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.close > span:not(.sr-only) {
  /* background-color: transparent; */
  /* color: rgba(0,0,0,.6); */
  /* line-height: 17px; */
  /* height: 1.25rem; */
  width: 1.25rem;
  /* border-radius: 50%; */
  font-size: 1.25rem;
  display: block;
  transition: all 0.15s ease;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 0 solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.17);
  }
  .modal-sm {
    max-width: 380px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.alert-dismissible {
  padding-right: 4.5rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  color: inherit;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.alert-dismissible .close {
  top: 50%;
  right: 1.5rem;
  padding: 0;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
.alert-dismissible .close:hover,
.alert-dismissible .close:focus {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1 !important;
}
@media (max-width: 575.98px) {
  .alert-dismissible .close {
    top: 1rem;
    right: 0.5rem;
  }
}
.alert-dismissible .close > span:not(.sr-only) {
  font-size: 1.5rem;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}
.alert-dismissible .close:hover > span:not(.sr-only),
.alert-dismissible .close:focus > span:not(.sr-only) {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.9);
}

.close > span:not(.sr-only) {
  /* background-color: transparent; */
  /* color: rgba(0,0,0,.6); */
  /* line-height: 17px; */
  /* height: 1.25rem; */
  width: 1.25rem;
  /* border-radius: 50%; */
  font-size: 35px;
  display: block;
  transition: all 0.15s ease;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.17);
  }
  .modal-sm {
    max-width: 380px;
  }
}

.text-center {
  text-align: center !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-fluid .modal-content {
  border-radius: 0;
}

.modal-primary .modal-title {
  color: #fff;
}

.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-primary .modal-content {
  background-color: #5e72e4;
  color: #fff;
}
.modal-primary .modal-content .heading {
  color: #fff;
}

.modal-primary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-secondary .modal-title {
  color: #212529;
}

.modal-secondary .modal-header,
.modal-secondary .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}

.modal-secondary .modal-content {
  background-color: #f7fafc;
  color: #212529;
}
.modal-secondary .modal-content .heading {
  color: #212529;
}

.modal-secondary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-success .modal-title {
  color: #fff;
}

.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-success .modal-content {
  background-color: #2dce89;
  color: #fff;
}
.modal-success .modal-content .heading {
  color: #fff;
}

.modal-success .close > span:not(.sr-only) {
  color: #fff;
}

.modal-info .modal-title {
  color: #fff;
}

.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-info .modal-content {
  background-color: #11cdef;
  color: #fff;
}
.modal-info .modal-content .heading {
  color: #fff;
}

.modal-info .close > span:not(.sr-only) {
  color: #fff;
}

.modal-warning .modal-title {
  color: #fff;
}

.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-warning .modal-content {
  background-color: #fb6340;
  color: #fff;
}
.modal-warning .modal-content .heading {
  color: #fff;
}

.modal-warning .close > span:not(.sr-only) {
  color: #fff;
}

.modal-danger .modal-title {
  color: #fff;
}

.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-danger .modal-content {
  background-color: #f5365c;
  color: #fff;
}
.modal-danger .modal-content .heading {
  color: #fff;
}

.modal-danger .close > span:not(.sr-only) {
  color: #fff;
}

.modal-light .modal-title {
  color: #fff;
}

.modal-light .modal-header,
.modal-light .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-light .modal-content {
  background-color: #adb5bd;
  color: #fff;
}
.modal-light .modal-content .heading {
  color: #fff;
}

.modal-light .close > span:not(.sr-only) {
  color: #fff;
}

.modal-dark .modal-title {
  color: #fff;
}

.modal-dark .modal-header,
.modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-dark .modal-content {
  background-color: #212529;
  color: #fff;
}
.modal-dark .modal-content .heading {
  color: #fff;
}

.modal-dark .close > span:not(.sr-only) {
  color: #fff;
}

.modal-default .modal-title {
  color: #fff;
}

.modal-default .modal-header,
.modal-default .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-default .modal-content {
  background-color: #172b4d;
  color: #fff;
}
.modal-default .modal-content .heading {
  color: #fff;
}

.modal-default .close > span:not(.sr-only) {
  color: #fff;
}

.modal-white .modal-title {
  color: #212529;
}

.modal-white .modal-header,
.modal-white .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}

.modal-white .modal-content {
  background-color: #fff;
  color: #212529;
}
.modal-white .modal-content .heading {
  color: #212529;
}

.modal-white .close > span:not(.sr-only) {
  color: #fff;
}

.modal-neutral .modal-title {
  color: #212529;
}

.modal-neutral .modal-header,
.modal-neutral .modal-footer {
  border-color: rgba(33, 37, 41, 0.075);
}

.modal-neutral .modal-content {
  background-color: #fff;
  color: #212529;
}
.modal-neutral .modal-content .heading {
  color: #212529;
}

.modal-neutral .close > span:not(.sr-only) {
  color: #fff;
}

.modal-darker .modal-title {
  color: #fff;
}

.modal-darker .modal-header,
.modal-darker .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-darker .modal-content {
  background-color: black;
  color: #fff;
}
.modal-darker .modal-content .heading {
  color: #fff;
}

.modal-darker .close > span:not(.sr-only) {
  color: #fff;
}

.text-white {
  color: #fff !important;
}
.btn-link {
  font-weight: 600;
  box-shadow: none;
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, #f7fafc 0, #f7f8fc 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #212529 0, #212229 100%) !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-neutral {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-darker {
  background: linear-gradient(87deg, black 0, black 100%) !important;
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}

.bg-gradient-indigo {
  background: linear-gradient(87deg, #5603ad 0, #9d03ad 100%) !important;
}

.bg-gradient-purple {
  background: linear-gradient(87deg, #8965e0 0, #bc65e0 100%) !important;
}

.bg-gradient-pink {
  background: linear-gradient(87deg, #f3a4b5 0, #f3b4a4 100%) !important;
}

.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important;
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
}

.bg-gradient-yellow {
  background: linear-gradient(87deg, #ffd600 0, #beff00 100%) !important;
}

.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #678f8e 100%) !important;
}

.bg-gradient-teal {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
}

.bg-gradient-cyan {
  background: linear-gradient(87deg, #2bffc6 0, #2be0ff 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #fff 0, white 100%) !important;
}

.bg-gradient-gray {
  background: linear-gradient(87deg, #8898aa 0, #888aaa 100%) !important;
}

.bg-gradient-gray-dark {
  background: linear-gradient(87deg, #32325d 0, #44325d 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #ced4da 0, #cecfda 100%) !important;
}

.bg-gradient-lighter {
  background: linear-gradient(87deg, #e9ecef 0, #e9eaef 100%) !important;
}
