
.tableHeadRow {
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
}

.tableBodyRow {
  cursor: 'pointer';
}

.tableBodyRow:hover {
  background-color: '#cecece';
  font-weight: bold;
}

/* FORM */
.card {
  background-color: #fff;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.card-container-larger.card {
  max-width: 600px;
  max-height: 100%;
  overflow-y: auto;
  padding: 40px 40px;
}

.card-container.card {
  max-width: 600px;
  max-height: 100%;
  overflow-y: auto;
  padding: 40px 40px;
}

.form-general {
  display: flex;
  flex-direction: column;
}

.form-general .form-general-input,
.form-general .form-general-button {
  margin-bottom: 18px;
}
.form-page {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.form-page-no-modal {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 50px;
}

.image-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
}

.div-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.div-sticky-header2 {
  top: 60px;
}

.floatingActionButons {
  display: flex;
  flex-direction: row;
  float: right;

  margin-left: -144px;
  margin-top: -25px;
}

/* Overrides of Antd selectors for SideBarMenu */
/* .ant-drawer-body { */
.ant-menu.ant-menu-dark {
  background-color: #15395b;
}